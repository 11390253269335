import React, { Component } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { InputAdornment, withStyles } from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { FaKey, FaEye, FaEyeSlash } from "react-icons/fa"

const styles = theme => ({
  eye: {
    cursor: "pointer",
  },
})

class Password extends Component {
  state = {
    passwordIsMasked: true,
    name: "password",
    label: "Password",
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }))
  }

  render() {
    const {
      classes,
      error,
      name,
      label,
      value,
      ui,
      placeholder,
      ...rest
    } = this.props
    const { passwordIsMasked } = this.state

    return (
      <div className="form-group">
        {ui === "material" && (
          <TextField
            {...rest}
            name={name}
            margin="dense"
            id={name}
            label={label}
            type={passwordIsMasked ? "password" : "text"}
            className="form-control"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordIsMasked ? (
                    <VisibilityOff
                      className={classes.eye}
                      onClick={this.togglePasswordMask}
                    />
                  ) : (
                    <Visibility
                      className={classes.eye}
                      onClick={this.togglePasswordMask}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        {ui === "bootstrap" && (
          <div className="l">
            <label htmlFor={name}>{label}</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  <FaKey />
                </span>
              </div>
              <input
                {...rest}
                value={value}
                name={name}
                id={name}
                label={label}
                className="form-control"
                placeholder={placeholder}
                type={passwordIsMasked ? "password" : "text"}
              />
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  {passwordIsMasked ? (
                    <FaEyeSlash onClick={this.togglePasswordMask} />
                  ) : (
                    <FaEye onClick={this.togglePasswordMask} />
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    )
  }
}

Password.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Password)
