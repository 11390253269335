import React from "react"
import TextField from "@material-ui/core/TextField"
import { FaUser, FaEnvelope, FaKey, FaLock } from "react-icons/fa"

const Input = ({
  name,
  label,
  error,
  errorDisabled = false,
  ui,
  value,
  placeholder,
  ...rest
}) => {
  return (
    <div className="form-group">
      {ui === "material" && (
        <TextField
          {...rest}
          name={name}
          margin="dense"
          id={name}
          label={label}
          value={value}
          className="form-control"
        />
      )}
      {ui === "bootstrap" && (
        <div className="l">
          <label htmlFor={name}>{label}</label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon3">
                {(name === "first_name" || name === "last_name") && <FaUser />}
                {(name === "email" || name === "username") && <FaEnvelope />}
                {name === "password" && <FaKey />}
                {(name === "store_code" || name === "visionCareCode") && (
                  <FaLock />
                )}
              </span>
            </div>
            <input
              {...rest}
              value={value}
              name={name}
              id={name}
              label={label}
              className="form-control"
              placeholder={placeholder}
            />
          </div>
        </div>
      )}
      {error && !errorDisabled && (
        <div className="alert alert-danger">{error}</div>
      )}
    </div>
  )
}

export default Input
